.mediaWrapper {
    position: relative;
    height: 100%;
    width: 100%;
    
}
.heroPadding {
    margin-top: var(--spacing-7);
    margin-bottom: var(--spacing-7);
    &:first-child {
        margin-top: 0;
    }
}
.m10Wrapper {
    position: relative;
    width: 100%;
    display: grid;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: hidden;

}
.m10Wrapper [class*='alignContent'] {
    padding: var(--spacing-4);
    z-index: 1;
    line-height: var(--line-height-compact);
    @media --md {
        padding: var(--spacing-7);
    }
}

/* heroHeight variant */
.narrowHeight {
    min-height: 300px;
    @media --md {
        min-height: 370px;
    }
}
.normalHeight {
    min-height: 400px;
    @media --md {
        min-height: 560px;
    }
}
.tallHeight {
    min-height: 600px;
    @media --md {
        min-height: 800px;
    }
}
.m10Content {
    height: auto;
    max-height: 1200px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    @media --xs {
        max-width: 100vw;
    }
}
.subHeadlineWidth { 
    width: 100%;
    @media --sm {
        width: 75%;
    }
}

.headlineWidth {  
    width: 100%;
    @media --sm {
        width: 50%;
    }
}

.fullWidth {
    width: 100%;
}
.leftMedia [class*='alignContent'] {
    @media --sm {
        padding-left: var(--spacing-4);
    }
    @media --md {
        padding-left: var(--spacing-7);
    }
}
.rightMedia [class*='alignContent'] {
    @media --sm {
        padding-right: var(--spacing-4);
    }
    @media --md {
        padding-right: var(--spacing-7);
    }
}
.fullMedia .mediaWrapper {
    top: 0;
    left: 0;
    position: absolute;
}
.fullMedia [class*='alignContent'] {
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    justify-content: end;

    @media --sm {
        justify-content: center;
        padding-left: 250px;
    }
}
.splitAlign {
    display: flex;
    flex-direction: column-reverse;
    
    @media --md {
        padding: var(--spacing-4);
        display: grid;
        grid-template-columns: 50%;
    }
}
.splitMedia {
    display: flex;
    flex-direction: column-reverse;

    @media --md {
        display: flex;
        flex-direction: row;

        & > div {
            width: 50%;
        }

        &.left {
            flex-direction: row-reverse;
        }
    }
}

.splitMedia .mediaWrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 1.35;
    @media --md {
        width: 50%;
        aspect-ratio: unset;
    }

    &.videoAspect {
        @media --md {
            aspect-ratio: 1.77;
            align-self: center;
            height: auto;
        }
    }
}
